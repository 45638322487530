<template>
  <div class="sub-nav __img">
    <p class="tit" ref="tit" @click="onClickTit">{{ TITLE[routeName] }}</p>
    <ul ref="ul">
      <li :class="{ on: routeName === 'resumeCareer' }" @click="onClickTit">
        <router-link to="/resume/career">경력</router-link>
      </li>
      <li :class="{ on: routeName === 'resumeGraduate' }" @click="onClickTit">
        <router-link to="/resume/graduate">학력</router-link>
      </li>
      <li :class="{ on: routeName === 'resumeCert' }" @click="onClickTit">
        <router-link to="/resume/cert">자격증</router-link>
      </li>
      <li :class="{ on: routeName === 'resumeLang' }" @click="onClickTit">
        <router-link to="/resume/lang">어학</router-link>
      </li>
      <li :class="{ on: routeName === 'resumeIntroduce' }" @click="onClickTit">
        <router-link to="/resume/introduce">자기소개서</router-link>
      </li>
      <!-- <li :class="{ on: routeName === 'resumeEdu' }" @click="onClickTit">
        <router-link to="/resume/edu">호구153교육 이수내역</router-link>
      </li> -->
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      TITLE: Object.freeze({
        resumeIntroduce: '자기소개서',
        resumeCareer: '경력',
        resumeGraduate: '학력',
        resumeCert: '자격증',
        resumeLang: '어학',
        // resumeEdu: '호구153교육 이수관리',
      }),
    }
  },
  methods: {
    onClickTit() {
      //모바일 레이아웃에서만 실행
      if (matchMedia('screen and (max-width: 1024px)').matches) {
        this.$refs['tit'].classList.toggle('open')
        this.$refs['ul'].classList.toggle('on')
        this.$emit('toggle')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.side-wrap .sub-nav {
  > ul > li > a {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
  }
}
</style>
