<template>
  <section class="sub-container" ref="sub-container">
    <sub-nav-with-user-info :open="navOpen">
      <sub-nav @toggle="onNavToggle"></sub-nav>
    </sub-nav-with-user-info>
    <main class="sub">
      <div class="inner">
        <sub-title
          title="이력서 관리"
          description="회원의 이력서를 관리할 수 있습니다."
        ></sub-title>
        <router-view ref="sub-cont"></router-view>
      </div>
      <div class="btn-wrap">
        <button
          type="button"
          class="link left"
          @click="goToPrevPage"
          v-show="prevPage"
          style="margin-right: 5px"
        >
          <span>이전</span>
        </button>
        <button
          type="button"
          class="link right"
          @click="goToNextPage"
          v-show="nextPage"
        >
          <span :style="resumeBtn">{{ SaveNext }}</span>
        </button>
      </div>
    </main>
  </section>
</template>

<script>
import SubNavWithUserInfo from 'Common/SubNavWithUserInfo'
import SubNav from 'Components/resume/partials/SubNav'
import SubTitle from 'Common/SubTitle'

export default {
  components: {
    SubNavWithUserInfo,
    SubNav,
    SubTitle,
  },
  data() {
    return {
      navOpen: false,
      prevPage: '',
      nextPage: '',
      order: {
        ['career']: ['', 'graduate'],
        ['graduate']: ['career', 'cert'],
        ['cert']: ['graduate', 'lang'],
        ['lang']: ['cert', 'introduce'],
        ['introduce']: ['lang', 'introduce'],
        // ['edu']: ['introduce', ''],
      },
      window: {
        width: 0,
        height: 0,
      },
    }
  },
  computed: {
    showDelete() {
      if (this.routeName !== 'resume') return true
      return false
    },
    SaveNext() {
      if (this.$route.name === 'resumeIntroduce') {
        return '저장'
      }
      return '저장 및 다음'
    },
    resumeBtn() {
      if (this.$route.name === 'resumeIntroduce') {
        return 'background:none; padding:0;'
      } else if (this.window.width > 1023) {
        return 'background: url(../asset/image/ico/ico-arrow-right.svg) no-repeat 100% 50%/8px 15px; padding-right: 15px;'
      } else {
        return 'background: url(../asset/image/ico/ico-arrow-right.svg) no-repeat 100% 50%/2.13333vw 4vw; padding-right: 4vw;'
      }
    },
  },
  methods: {
    goToPrevPage() {
      // 교육 이수 확인 페이지에서는 제외
      if (this.nextPage) {
        this.saveItem()
      }
      this.$router.push('/resume/' + this.prevPage)
    },
    async goToNextPage() {
      await this.saveItem()
      await this.$router.push('/resume/' + this.nextPage)
    },
    onNavToggle() {
      this.navOpen = !this.navOpen
    },
    setMovePage() {
      let currentPage = window.location.pathname.split('/')[2]
      this.prevPage = this.order[currentPage][0]
      this.nextPage = this.order[currentPage][1]
    },
    saveItem() {
      this.$refs['sub-cont'].save()
    },
    handleResize() {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    },
  },
  watch: {
    $route() {
      this.setMovePage()
    },
  },
  created() {
    this.setMovePage()
    window.$('.main').css('height', window.innerHeight)
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
}
</script>

<style></style>
